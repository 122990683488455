/* src/components/projects/Projects.css */
.project-dashboard {
  height: 100%;
  background-color: #FFFFEE;
}

.price-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  backdrop-filter: blur(10px); /* Glass effect */
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  width: 100%;
  max-width: 1200px;
}

.plans {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  align-items: flex-start; /* Align all cards to the top */
}

.plan-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align content to the left */
  background: #151922; /* Slightly darker background */
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease;
  width: 30%;
  height: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.plan-card:hover {
  transform: scale(1.05);
  background: rgba(255, 255, 255, 0.3); /* Highlight on hover */
}

.plan-header h3 {
  font-size: .75em;
  color: black;
  background: #83fb78;
  padding: 5px 10px;
  border-radius: 5px;
  max-width: fit-content; /* Ensure the tab does not stretch */
}

.plan-header h1 {
  font-size: 2.5em;
  font-weight: bold;
}

.plan-header p {
  font-size: 1.1em;
}

.plan-features {
  list-style-type: none;
  padding-left: 0; /* Remove padding */
  width: 100%;
}

.plan-features li {
  margin: 5px 0;
}

.plan-features li::before {
  content: '✓';
  color: #83fb78;
  margin-right: 8px;
}

.project-dashboard {
  color: black;
}


.subscription-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin: 20px;

}

.subscription-card h2 {
  color: #333;
  margin-bottom: 20px;
  font-size: 24px;
}

.subscription-card p {
  margin-bottom: 12px;
  line-height: 1.5;
}

.subscription-card strong {
  color: #555;
}

.warning {
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: 4px;
  padding: 12px;
  margin-top: 20px;
}

.warning p {
  color: #856404;
  margin-bottom: 10px;
}

.dashboard-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 20px;
}

.dashboard-button {
  background-color: #151922;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 4px;
  cursor: pointer;
  max-width: 90%;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.dashboard-button::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: white;
  z-index: -1;
}

.dashboard-button::after {
  content: '';
  position: absolute;
  inset: 2px;
  background-color: #151922;
  z-index: -1;
  transition: background-color 0.3s;
}

.dashboard-button:hover::before {
  background: linear-gradient(
    135deg,
    #FFD650 0%,
    #FF7A79 20%,
    #4FFF8A 40%,
    #4B9FFF 60%,
    #B63ACE 80%,
    #FFD650 100%
  );
  filter: blur(1px);
}

.selected-langs {
  padding-left: 5px;
}

.dashboard-button:hover::after {
  background-color: #1a1f2a;
}

.dashboard-button:hover {
  color: white;
  border: none;
}

.subscription-page {
  color: black;
}

.free-plan-message {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: 10px;
  margin-top: 10px;
  border-radius: 4px;
}

.free-plan-message p {
  margin: 0;
  color: #666;
}

.dashboard-button.locked {
  opacity: 0.6;
  cursor: not-allowed;
}

.lock-icon {
  width: 16px;
  height: 16px;
  margin: 0 5px;
}

.danger-zone {
  color: black;
}

.edit-icon.wordpress-edit {
  position: relative;
}

.edit-icon.wordpress-edit::after {
  content: attr(title);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.edit-icon.wordpress-edit:hover::after {
  opacity: 1;
  visibility: visible;
}

.free-plan-notice {
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  color: #721c24;
  padding: 10px;
  margin-top: 10px;
}

.dashboard-button.locked {
  opacity: 0.6;
  cursor: not-allowed;
}

.lock-icon {
  width: 16px;
  height: 16px;
  margin: 0 5px;
}

.dashboard-button .lock-icon {
  vertical-align: middle;
}
.dashboard {
    display: flex;
    height: 100vh;
    background-color: #151922;
}

.dashboard-content {
    flex: 1;
    overflow-y: auto;
    border-bottom-left-radius: 30px;
}

/* Dashboard.css */

.dashboard {
    display: flex;
    height: 100vh;
}

.dashboard-main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

@media (max-width: 600px) {
    .dashboard-content {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }
  }
  
/* src/components/projects/Payment.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container {
  background: #151922;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 500px;
  color: white;
  width: 100%;
}

.modal-container h1 {
  margin-bottom: 20px;
}

.modal-container p {
  margin-bottom: 20px;
}

.modal-container button {
  padding: 10px 20px;
  background-color: white;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

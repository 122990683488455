.form-container {
  background-color: #151922;
  display: block;
  width: 100%;
  max-width: 600px;
  padding: 20px;
  margin: 0 auto;
  border: 3px solid #B63ACE;
  box-shadow:
    10px -10px 0 -3px #FFFFEE, 10px -10px #4B9FFF,
    20px -20px 0 -3px #FFFFEE, 20px -20px #4FFF8A, 
    30px -30px 0 -3px #FFFFEE, 30px -30px #FF7A79, 
    40px -40px 0 -3px #FFFFEE, 40px -40px #FFD650; 
  transition: box-shadow 1s, left 1s, top 1s;
  cursor: pointer;
}

.form-container:hover {
  top: -40px;
  left: 40px;
  box-shadow:
    0 0 0 -3px #FFFFEE, 0 0 0 0 #4B9FFF,
    0 0 0 -3px #FFFFEE, 0 0 0 0 #4FFF8A, 
    0 0 0 -3px #FFFFEE, 0 0 0 0 #FF7A79, 
    0 0 0 -3px #FFFFEE, 0 0 0 0 #FFD650; 
}

.project-page {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #FFFFEE;
  color: white;
  min-height: 90vh;
  padding: 20px;
}

.form-title {
  font-size: 24px;
  color: white;
}

.form-description {
  font-size: 16px;
  margin-bottom: 20px;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
}

.form-group {
  flex: 1 1 290px;
  padding: 5px;
}

.form-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-input, .form-select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-select {
  margin-top: 10px;
}

.form-select {
  cursor: pointer;
}

.form-button {
  padding: 10px 20px;
  background-color: #ffffff;
  color: black;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
}

.form-button:last-child {
  margin-right: 0;
}

.form-button:hover {
  background-color: #000000;
  color: white;
  border: 1px solid white;
}

.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
}

input[type="checkbox"], input[type="radio"] {
  accent-color: #83fb78;
}

.dns-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
  text-align: left;
}

.dns-table th, .dns-table td {
  padding: 8px;
  border: 1px solid #ccc;
}

.dns-table th {
  background-color: #000000;
}

.status-verified {
  color: green;
}

.status-not-verified {
  color: red;
}

.verification-status {
  margin-top: 20px;
  font-style: italic;
}

/* Progress Bar CSS */
.progress-bar {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
  margin-bottom: 5%;
}

.progress-step {
  background-color: #151922;
  color: white;
  padding: 10px 20px;
  border-radius: 2px;
  flex-grow: 1;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;  /* Add spacing between progress bars */
  border: 1px solid #B63ACE;
  cursor: pointer;
}

.progress-step:last-child {
  margin-right: 0;
}

.progress-step span {
  flex-grow: 1;
  text-align: center;
}

.progress-step .delete-icon {
  margin-left: auto;
  cursor: pointer;
  background: url('../assets/trash.svg') no-repeat center center;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.plan-card {
  background: rgba(255, 255, 255, 0.1); /* Semi-transparent white background */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Light white border for a glassy edge */
  backdrop-filter: blur(10px); /* Blur the background behind the card */
  color: white; /* White text color */
  padding: 20px;
  border-radius: 10px; /* Rounded corners */
  margin: 10px;
  transition: transform 0.3s ease-in-out;
}

.plan-card:hover {
  transform: scale(1.05); /* Slight scale on hover for interactive effect */
}

.price-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.price-table h2, .plan-card h3, .plan-card p, .plan-card ul {
  color: white; /* Ensuring all text is white for consistency */
}

.cname-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.cname-table td {
  padding: 10px;
  border: 1px solid #ddd;
}

.copy-message {
  color: green;
  font-weight: bold;
}

.warning-message {
  color: yellow;
}

.help-icon {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}

.help-section {
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ffffff;
  border-radius: 5px;
}

.help-section h3 {
  margin-top: 0;
}

.help-section ol {
  padding-left: 20px;
}

.help-section li {
  margin-bottom: 5px;
}

.error-message {
  color: red;
}

.webflow-message {
  background-color: var(--random-color);
  border: 1px solid var(--random-border-color);
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 20px;
}

.webflow-message p {
  color: black;
  margin: 0;
}

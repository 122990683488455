.language-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  .language-modal-setup {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    background-color: #FFFFEE ;
  }
  
  .language-modal-container {
    background-color: #151922;
    color: white;
    padding: 2rem;
    border-radius: 12px;
    width: 90%;
    max-width: 600px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .language-close-icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    width: 24px;
    height: 24px;
    opacity: 0.6;
    transition: opacity 0.3s ease;
  }
  
  .language-close-icon:hover {
    opacity: 1;
  }
  
  .language-modal-title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  .language-modal-description {
    font-size: 1rem;
    color: #b2b2b2;
    margin-bottom: 1.5rem;
  }
  
  .language-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .language-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 1rem;
    border-radius: 8px;
    transition: all 0.3s ease;
    border: 2px solid white;
  }
  
  .language-item:hover {
    background-color: #f0f0f0;
  }
  
  .language-selected {
    background: white;
    color: black;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .language-flag-container {
    position: relative;
    width: 60px;
    height: 40px;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .language-flag {
    width: 100%;
    height: auto;
  }
  
  .language-flag-overlap {
    position: absolute;
    left: 20px;
    z-index: 1;
  }
  
  .language-name {
    font-size: 0.9rem;
    text-align: center;
  }
  
  .language-count {
    text-align: center;
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  
  .language-save-button {
    display: block;
    width: 100%;
    padding: 0.75rem;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .language-save-button:hover {
    background-color: #0056b3;
  }
  
  .language-save-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 90vh;
    padding: 2rem;
    background-color: #FFFFEE;
}

.home-content {
    background-color: rgb(255, 255, 255);
    padding: 2rem;
    border-radius: 10px;
    text-align: center;
    z-index: 1;
    max-width: 600px;
    width: 90%;
}

.home-logo {
    width: 150px;
    margin-bottom: 2rem;
}

.create-project-button,
.referral-button {
    border: none;
    color: white;
    padding: 0.8rem 1.5rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.1rem;
    margin: 0.5rem;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
}

.create-project-button:hover,
.referral-button:hover {
    opacity: 0.8;
    border: 1px solid black;
}

.referral-info {
    font-size: 0.9rem;
    color: #7f8c8d;
    margin-top: 0.5rem;
}

.report-bug-link {
    font-size: 0.9rem;
    color: #e74c3c;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 2rem;
}

.report-bug-link:hover {
    color: #c0392b;
}

.benjis-link {
    color: #FF0000;
    text-decoration: none;
    transition: color 0.3s;
}

.benjis-link:hover {
    color: #FF7A79;
    text-decoration: underline;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  background: #FFFFEE;
}

.contain {
  position: relative;
  width: 130px;
  height: 130px;
}

.contain svg {
  position: absolute;
  top: 0;
  left: 0;
}

.contain svg circle {
  animation: draw 3s infinite;
}

.contain svg:nth-of-type(1) circle {
  stroke: #FFD650;
}

.contain svg:nth-of-type(2) circle {
  r: 54px;
  stroke-dashoffset: 171px;
  stroke-dasharray: 171px;
  stroke: #FF7A79;
  animation-delay: 100ms;
}

.contain svg:nth-of-type(3) circle {
  r: 48px;
  stroke-dashoffset: 152px;
  stroke-dasharray: 152px;
  stroke: #4FFF8A;
  animation-delay: 200ms;
}

.contain svg:nth-of-type(4) circle {
  r: 43px;
  stroke-dashoffset: 136px;
  stroke-dasharray: 136px;
  stroke: #4B9FFF;
  animation-delay: 300ms;
}

.contain svg:nth-of-type(5) circle {
  r: 37px;
  stroke-dashoffset: 117px;
  stroke-dasharray: 117px;
  stroke: #B63ACE;
  animation-delay: 400ms;
}

@keyframes draw {
  50% {
    stroke-dashoffset: 90px;
    stroke-dasharray: 60px, 20px, 7px, 3px;
  }
}
@keyframes rainbow {
  0% { box-shadow: 0 0 10px #FFD650; }
  20% { box-shadow: 0 0 10px #FF7A79; }
  40% { box-shadow: 0 0 10px #4FFF8A; }
  60% { box-shadow: 0 0 10px #4B9FFF; }
  80% { box-shadow: 0 0 10px #B63ACE; }
  100% { box-shadow: 0 0 10px #FFD650; }
}

.translation-page {
  padding: 20px;
  position: relative;
  min-width: 100%;
  max-width: 1200px;
  min-height: 100%;
  background-color: #FFFFEE;
  margin: 0 auto;
}

.back-icon {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.edit-title {
  padding-top: 10px;
}

.search-bar {
  margin-bottom: 20px;
  max-width: 95%;
}

.search-bar input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 20px;
  transition: all 0.3s ease;
}

.search-bar input:focus {
  outline: none;
  animation: rainbow 5s linear infinite;
}

.language-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.language-chip {
  padding: 5px 10px;
  border-radius: 20px;
  color: white;
  cursor: pointer;
  transition: opacity 0.3s;
}

.language-chip:hover {
  opacity: 0.8;
}

.language-chip.active {
  box-shadow: 0 0 0 2px white, 0 0 0 4px currentColor;
}

.pages-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  max-width: 90%;
}

.page-item {
  background-color: white;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.page-item a {
  text-decoration: none;
  color: #333;
  word-break: break-word;
}

.page-item a:hover {
  text-decoration: underline;
}

.edit-translations-page {
  margin: 0 auto;
  padding: 20px;
}

h1 {
  color: #333;
  margin-bottom: 20px;
}

.search-bar {
  margin-bottom: 20px;
}

.translations-grid {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 95%;
  
}

.translation-card {
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 15px;
  transition: box-shadow 0.3s ease;
}

.translation-card:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.original-text {
  font-weight: bold;
  margin-bottom: 8px;
  color: #555;
}

.translated-text {
  color: #333;
  cursor: pointer;
}

.translated-text input {
  width: 100%;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 3px;
  font-size: 14px;
}

.loading, .error, .no-translations {
  text-align: center;
  color: #666;
  margin-top: 20px;
}

.error {
  color: #d32f2f;
}
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  
  .modal-close-button {
    float: right;
    border: none;
    background: none;
    font-size: 16px;
    cursor: pointer;
  }
  
  /* Add mobile styling */
  @media (max-width: 768px) {

    .modal-close-button img {
      width: 15px; /* Reduce image size for smaller screens */
    }
  }
.auth-container {
  color: rgb(0, 0, 0);
  display: flex;
  min-height: 100vh;
}

.artwork-section {
  flex: 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 30px 0 0 30px;
}

.login-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.forgot-password-link {
  margin-top: 10px;
  text-align: center;
  width: 100%;
}

:root {
  --random-color: #000000;
}

:any-link {
  color: var(--random-color);
}

.login-side {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.background-canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Make sure it stays behind other content */
}


.login-card {
  display: flex;
  flex-direction: column;
  align-items: center; /* Aligns child elements (including buttons) to the center */
  width: 80%;
  max-width: 450px;
  padding: 20px;
  border-radius: 25px;
  backdrop-filter: blur(12px); /* Lower blur value for a more subtle effect */
  background: rgba(255, 255, 255, 0.65); /* Use a semi-transparent white for glass effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3); /* Subtle white border for added depth */
}


input[type="text"], 
input[type="email"], 
input[type="password"] {
  width: 100%; /* Ensures input fields cover the full width */
  box-sizing: border-box;
  margin: 10px 0;
  padding: 10px;
  border: none;
  border-radius: 5px;
  border: 1px solid #ccc;
}

/* Style for both buttons */
button, 
button.google-button {
  width: 100%;             /* Set button width */
  box-sizing: border-box;
  margin: 10px 0;
  padding: 10px;
  border: none;
  border-radius: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
  background-color: #000000;
  color: #fff;
}

input[type="checkbox"] {
  width: auto;
  margin-right: 10px;
  vertical-align: middle;
}

label {
  display: block;
  margin: 5px 0;
}

button {
  cursor: pointer;
  color: #fff;
}

#hearts {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(135deg, #7f00ff, #e100ff);
}

.auth-label {
  width: 100%;
  
}

.google-button {
  display: flex;
  justify-content: center;
  color: black;
  background-color: #fff;
  align-items: center;
}

.google-logo {
  margin-right: 10px;
  max-height: 20px;
}

.error-message {
  color: red;
  margin: 10px 0;
}

.or-divider {
  text-align: center;
  margin: 10px 0;
}

.terms-checkbox {
  display: flex;
  align-items: center;  /* Fixed alignment here */
  margin: 10px 0;
}

.terms-checkbox input[type="checkbox"] {
  margin-right: 5px;
}

.terms-checkbox span {
  vertical-align: middle;
}

@media (max-width: 768px) {
  .auth-container {
    flex-direction: column;
  }

  .artwork-section {
    display: none;
  }
}

a {
  cursor: pointer;
  color: #141E4F;
  text-decoration: none;
}

.input-login {
  width:100%
}

.login-button {
  background-color: black;
  box-shadow: 0 3px 3px #00000040;
  color: white;
}

.logo {
  width: 50%;
  max-width: 100px;
}
.modal-actions {
  display: flex;
  justify-content: space-around; /* Space between the buttons */
  gap: 20px; /* Add gap between the buttons */
  margin-top: 20px;
}

.confirm-button, .cancel-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.confirm-button {
  background-color: #dc3545; /* Red background for delete */
  color: white;
}

.confirm-button:hover {
  background-color: #c82333; /* Darker red on hover */
}

.cancel-button {
  background-color: #6c757d; /* Grey background for cancel */
  color: white;
}

.cancel-button:hover {
  background-color: black; /* Darker grey on hover */
  color: white;
  border: 1px solid white;
}

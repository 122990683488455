.settings-container {
    height: 100%;
    background-color: #FFFFEE ;
    margin: auto;
    padding: 20px;
}

.error-message {
    color: red;
}

.danger {
    background-color: #ff2929;
    box-shadow: 0 3px 3px #00000040;
    color: #ffffff;
}

.form-group {
    margin-bottom: 20px;
}

input[type="email"], input[type="password"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
}
/* SideNav.css */

.side-nav {
  width: 5%;
  background-color: #151922;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh; /* Ensure it fills the full height */
  overflow: hidden; /* Hide any overflow */
  z-index: 1000;
}

.icon-logo-container {
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10%; /* Adjusted padding for better vertical alignment */
  padding-bottom: 20%; /* Adjusted padding for better vertical alignment */
}

.icon-logo-container .logo-link img {
  width: 35px; /* Standardize the logo size with icon size */
  height: 35px; /* Ensure consistent dimensions */
}

.nav-links {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align the links */
}

.nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px; /* Larger touch targets */
  color: white;
}

.nav-link img {
  width: 30px;
  height: 30px; /* Keep icons consistent */
}

.nav-link.selected img {
  border-radius: 50%; /* Circle around the icon */
  background-color: rgba(255, 255, 255, 0.5); /* Slight background highlight */
}

.add-project {
  width: 40px;
  height: 40px;
  background-color: #151922; /* Dark background to match the sidebar */
  color: white;
  font-size: 24px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  margin-bottom: 20px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  transition: transform 0.2s ease;
}

.add-project::before {
  content: '';
  position: absolute;
  inset: -2px; /* Shorthand for top, right, bottom, left */
  background: linear-gradient(
    135deg,
    #FFD650 0%,
    #FF7A79 20%,
    #4FFF8A 40%,
    #4B9FFF 60%,
    #B63ACE 80%,
    #FFD650 100%
  );
  border-radius: 50%;
  z-index: -1;
  opacity: 1; /* Fully visible */
  transition: filter 0.2s ease;
}

.add-project::after {
  content: '';
  position: absolute;
  inset: 2px; /* Slightly smaller than the button */
  background-color: #151922; /* Same as button background */
  border-radius: 50%;
  z-index: -1;
}

.add-project:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.add-project:hover::before {
  filter: brightness(1.2) blur(1px); /* Brighten and slightly blur on hover */
}

.add-icon {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.project-icon img {
  width: 40px;
  height: 40px;
  margin-bottom: 20px;
  cursor: pointer;
}

.support-logout {
  width: 100%;
  padding: 10px 0; /* Reduce bottom padding */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align the logout */
}

@media (max-width: 600px) {
  .side-nav {
    display: none; /* Hide sidebar on small screens */
  }
}

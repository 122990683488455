.dashboard-header {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFFFEE;
  color: black;
  padding: 10px 20px;
  max-height: 5vh;
  border-top-left-radius: 30px;
  z-index: 1;
}

.dashboard-title {
  flex-grow: 1;
}

.dashboard-nav {
  display: none;  /* Hide nav on large screens by default */
  flex-direction: column;  /* Stack items vertically */
  background-color: black;
  padding: 10px;
  border-radius: 5px;
  position: absolute;
  top: 100%;  /* Position dropdown below header */
  right: 0;  /* Align dropdown to the right */
  width: fit-content;  /* Adjust width based on content */
  transition: opacity 0.3s ease-in-out;  /* Smooth fade animation */
  opacity: 0;  /* Hide dropdown initially */
}

.dashboard-nav.open {
  display: flex;
  opacity: 1;  /* Show dropdown on open */
}

.dashboard-nav ul {
  list-style-type: none;
  padding: 0;
}

.dashboard-nav ul li {
  margin: 5px 0;
}

.dashboard-nav ul li a {
  color: white;
  text-decoration: none;
}

.dashboard-actions {
  display: flex;
  align-items: center;
}

.icon {
  width: 15px;
  height: 15px;
  margin-left: 20px;
  filter: brightness(0) invert(1); /* Invert color to white */
}

.menu-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}


@media only screen and (min-width: 768px) {
  .dashboard-nav {
    display: flex; /* Show nav on large screens */
    flex-direction: row; /* Display items horizontally */
    position: relative; /* Remove absolute positioning */
    background-color: transparent; /* Remove background color */
    padding: 0; /* Remove padding */
  }
  .dashboard-nav ul {
    flex-direction: row; /* Display items horizontally */
  }
  .dashboard-nav ul li {
    margin-right: 20px; /* Add margin between items */
  }
  .menu-button {
    display: none; /* Hide nav icon on large screens */
  }
}
/* ReportBugModal.css */
.report-bug-form {
    display: flex;
    flex-direction: column;
    color: white;
    gap: 1rem;
  }
  
  .report-bug-form h2 {
    margin-bottom: 1rem;
  }
  
  .report-bug-form label {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .report-bug-form input,
  .report-bug-form textarea {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .report-bug-form button {
    padding: 0.5rem;
    border: none;
    border-radius: 5px;
    background-color: white;
    color: black;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .report-bug-form button:hover {
    background-color: #FFFFEE;
  }
  
  .report-bug-link {
    margin-top: 1rem;
    cursor: pointer;
    color: blue;
    text-decoration: underline;
  }
  
  .report-bug-link:hover {
    text-decoration: none;
  }
  